
.privacy {
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
}

.listLink {
    list-style: unset;
    margin: 16px 0 16px 16px;
    padding-left: 40px;
}

.listLink > li > a {
    color: blue;
}