

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background: #f7f7f7;
}

.footer > div {
    padding-top: 10px;
}